<template>
  <empty-list :content="$t('organization.sections.activity')" />
</template>

<script>
export default {
  name: 'Activity',
  components: {
    EmptyList: () => import('@/components/EmptyList'),

  },
  data() {
    return {
      //
    }
  },
}
</script>
